import React, {useEffect} from 'react';
import Button from '../../Button';
import RemixIcon from '../../RemixIcon';
import styles from '@/view/styles/components/FeedCard/blocs/FeedCardHeader.module.scss';
import {ActionsMenuActions} from '@/state/hooks/actionsMenu';
import {getImageUrl} from '@/utils/conversions/conversions';
import {updateTimeSince} from '@/utils/timer';
import {format} from 'timeago.js';
import TokenPageLink from '../../TokenPageLink';
import {UserItem} from '../../UserItem';
import {FeedCardHeaderEventFragment$key} from './__generated__/FeedCardHeaderEventFragment.graphql';
import {FeedCardHeaderTokenFragment$key} from './__generated__/FeedCardHeaderTokenFragment.graphql';
import {graphql, useFragment} from '@/kits/relay-kit/src';
import TimestampTooltip from '../../TimestampTooltip';

const FeedCardHeaderEventFragment = graphql`
  fragment FeedCardHeaderEventFragment on events {
    id
    nodeId
    type
    accounts {
      id
      nodeId
      ...UserItemFragment
    }
    tezos_tokens {
      id
      nodeId
      type
      accounts {
        id
        nodeId
        ...UserItemFragment
      }
    }
  }
`;

const FeedCardHeaderTokenFragment = graphql`
  fragment FeedCardHeaderTokenFragment on tezos_tokens {
    id
    nodeId
    title
    description
    created_at
    thumbnail_uri
    artifact_uri
    metadata_uri
    editions
    editions_minted
    type
    tokens_sharesCollection(first: 1, orderBy: {share_count: DescNullsLast}) {
      edges {
        node {
          id
          nodeId
          share_count
        }
      }
    }
    accounts {
      id
      nodeId
      identities {
        id
        nodeId
        profilesCollection {
          edges {
            node {
              id
              nodeId
              username
            }
          }
        }
      }
      ...UserItemFragment
    }
  }
`;

export default function FeedCardHeader({
  event: eventKey,
  token: tokenKey,
}: {
  event: FeedCardHeaderEventFragment$key;
  token: FeedCardHeaderTokenFragment$key;
}) {
  const event = useFragment(FeedCardHeaderEventFragment, eventKey);
  const token = useFragment(FeedCardHeaderTokenFragment, tokenKey);
  const action = () => {
    switch (event.type) {
      case 'mint_tezos_token':
      case 'pending_mint_tezos_token':
      case 'purchase_tezos_token':
      case 'pending_purchase_tezos_token':
      case 'collect_for_free':
      case 'mint_for_free':
        return 'bought';
      case 'add_tezos_token_to_playlist':
        return 'added to a playlist';
      case 'follow':
        return 'followed';
      case 'repost':
        return 'reposted';
      default:
        return '';
    }
  };

  const title = () => {
    switch (event.type) {
      case 'follow':
        return <UserItem account={token.accounts} />;
      case 'create_tezos_token':
      case 'mint_tezos_token':
      case 'pending_mint_tezos_token':
      case 'purchase_tezos_token':
      case 'pending_purchase_tezos_token':
      case 'collect_for_free':
      case 'mint_for_free':
      case 'repost':
        switch (token.type) {
          case 'post':
            return '';
          default:
            return (
              <TokenPageLink tokenId={token.id} username={token.title}>
                {token.title}
              </TokenPageLink>
            );
        }
      case 'add_tezos_token_to_playlist':
        return <div>playlist</div>;
      default:
        return '';
    }
  };

  const [age, setAge] = React.useState('');

  useEffect(() => {
    updateTimeSince(() => {
      setAge(format(new Date(token.created_at).getTime() || Date.now()));
    });
  }, [token.created_at]);

  return (
    <>
      {event.type !== 'create_tezos_token' && (
        <div className={styles.eventInfo}>
          <UserItem account={event.accounts} hideText />
          <div>
            <UserItem account={event.accounts} hideIcon />
            <span>{action()}</span>
          </div>
        </div>
      )}
      <div className={styles.header}>
        <div>
          {event.tezos_tokens?.accounts && (
            <UserItem
              account={event.tezos_tokens.accounts}
              hideText
              showFollowButton
            />
          )}
          <div>
            <div>
              {event.tezos_tokens?.accounts && (
                <UserItem account={event.tezos_tokens.accounts} hideIcon />
              )}
              {event.tezos_tokens?.type !== 'post' ? (
                <>
                  <span>posted</span>
                  {title()}
                </>
              ) : (
                <span>posted</span>
              )}
            </div>
            <div>
              <TimestampTooltip
                date={token.created_at}
                className={styles.age}
              />
            </div>
          </div>
        </div>
        <TokenPageLink
          tokenId={token.id}
          username={token.title}
          className={styles.spacer}
        />
        <div>
          <Button
            icon
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              ActionsMenuActions.openActionsMenu({
                type: 'token',
                page: 0,
                id: token.id,
                nodeId: token.nodeId,
                title: token.title,
                description: token.description || undefined,
                image: getImageUrl(token.thumbnail_uri || ''),
                shareCount:
                  parseInt(
                    token.tokens_sharesCollection?.edges?.[0].node
                      ?.share_count || '0'
                  ) || 0,
                artifactUri: token.artifact_uri,
                creator: {
                  id: token.accounts.identities.id,
                  username:
                    token.accounts.identities.profilesCollection?.edges?.[0]
                      ?.node?.username || undefined,
                },
                editions: token.editions || undefined,
                editionsMinted: token.editions_minted || undefined,
                metadataUri: token.metadata_uri || undefined,
                tokenType: token.type || undefined,
              });
            }}
            tooltip="Actions"
            tooltipSide="left"
          >
            <RemixIcon icon="more-fill" size={16} />
          </Button>
        </div>
      </div>
    </>
  );
}
