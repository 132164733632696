/**
 * @generated SignedSource<<dfb073f87c347dd333c6c22050f5c880>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type enum_event_type = "add_tezos_token_to_playlist" | "block" | "collect_for_free" | "create_tezos_token" | "follow" | "mint_for_free" | "mint_tezos_token" | "pending_mint_tezos_token" | "pending_purchase_tezos_token" | "purchase_tezos_token" | "repost" | "%future added value";
export type enum_token_type = "audio" | "image" | "link" | "post" | "video" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type FeedCardHeaderEventFragment$data = {
  readonly accounts: {
    readonly id: string;
    readonly nodeId: string;
    readonly " $fragmentSpreads": FragmentRefs<"UserItemFragment">;
  };
  readonly id: string;
  readonly nodeId: string;
  readonly tezos_tokens: {
    readonly accounts: {
      readonly id: string;
      readonly nodeId: string;
      readonly " $fragmentSpreads": FragmentRefs<"UserItemFragment">;
    };
    readonly id: string;
    readonly nodeId: string;
    readonly type: enum_token_type;
  } | null;
  readonly type: enum_event_type;
  readonly " $fragmentType": "FeedCardHeaderEventFragment";
};
export type FeedCardHeaderEventFragment$key = {
  readonly " $data"?: FeedCardHeaderEventFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeedCardHeaderEventFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "accounts",
  "kind": "LinkedField",
  "name": "accounts",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserItemFragment"
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeedCardHeaderEventFragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "tezos_tokens",
      "kind": "LinkedField",
      "name": "tezos_tokens",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "events",
  "abstractKey": null
};
})();

(node as any).hash = "057bc0b2e553a49dc6b3a463ac786766";

export default node;
