import React, {useEffect} from 'react';
import Button from '../../Button';
import RemixIcon from '../../RemixIcon';
import styles from '@/view/styles/components/FeedCard/blocs/FeedCardHeader.module.scss';
import {graphql, useFragment} from '@/kits/relay-kit/src';
import {ActionsMenuActions} from '@/state/hooks/actionsMenu';
import {getImageUrl} from '@/utils/conversions/conversions';
import TimestampTooltip from '../../TimestampTooltip';
import {UserItem} from '../../UserItem';
import {FollowFeedCardHeaderEventFragment$key} from './__generated__/FollowFeedCardHeaderEventFragment.graphql';
import {FollowFeedCardHeaderFollowerFragment$key} from './__generated__/FollowFeedCardHeaderFollowerFragment.graphql';

const FollowFeedCardHeaderEventFragment = graphql`
  fragment FollowFeedCardHeaderEventFragment on events {
    id
    nodeId
    type
    created_at
    follows {
      followee {
        accountsCollection {
          edges {
            node {
              id
              nodeId
              ...UserItemFragment
            }
          }
        }
      }
    }
    accounts {
      identities {
        profilesCollection {
          edges {
            node {
              username
            }
          }
        }
      }
      ...UserItemFragment
    }
  }
`;

const FollowFeedCardHeaderFollowerFragment = graphql`
  fragment FollowFeedCardHeaderFollowerFragment on accounts {
    id
    nodeId
    type
    magic_accountsCollection {
      edges {
        node {
          id
          nodeId
          public_key_hash
        }
      }
    }
    beacon_accountsCollection {
      edges {
        node {
          id
          nodeId
          public_key_hash
        }
      }
    }
    teztok_accountsCollection {
      edges {
        node {
          id
          nodeId
          public_key_hash
        }
      }
    }
    identities {
      id
      nodeId
      profilesCollection {
        edges {
          node {
            id
            nodeId
            username
            name
            bio
            avatar_uri
          }
        }
      }
    }
    magic_accountsCollection {
      edges {
        node {
          id
          nodeId
          public_key_hash
        }
      }
    }
    beacon_accountsCollection {
      edges {
        node {
          id
          nodeId
          public_key_hash
        }
      }
    }
    teztok_accountsCollection {
      edges {
        node {
          id
          nodeId
          public_key_hash
        }
      }
    }
  }
`;

export default function FollowFeedCardHeader({
  eventKey,
  follower: followerKey,
}: {
  eventKey: FollowFeedCardHeaderEventFragment$key;
  follower: FollowFeedCardHeaderFollowerFragment$key;
}) {
  const event = useFragment(FollowFeedCardHeaderEventFragment, eventKey);
  const follower = useFragment(
    FollowFeedCardHeaderFollowerFragment,
    followerKey
  );

  const action = () => {
    switch (event.type) {
      case 'follow':
        return 'followed';
      default:
        return '';
    }
  };

  const title = () => {
    if (!event.follows?.followee.accountsCollection?.edges?.[0]?.node) {
      return '';
    }
    switch (event.type) {
      case 'follow':
      case 'block':
        return (
          <UserItem
            account={
              event.follows?.followee.accountsCollection?.edges?.[0]?.node
            }
            hideIcon
          />
        );
      default:
        return '';
    }
  };

  const followerAddress =
    follower.type === 'magic'
      ? follower?.magic_accountsCollection?.edges?.[0]?.node?.public_key_hash
      : follower.type === 'beacon'
      ? follower?.beacon_accountsCollection?.edges?.[0]?.node?.public_key_hash
      : follower.type === 'teztok'
      ? follower?.teztok_accountsCollection?.edges?.[0]?.node?.public_key_hash
      : '';

  return (
    <div className={styles.header}>
      <div>
        <UserItem account={event.accounts} hideText showFollowButton />
        <div>
          <div data-event-type="follow">
            <UserItem account={event.accounts} hideIcon />
            <span>{action()}</span>
            {title()}
          </div>
          <div>
            <TimestampTooltip date={event.created_at} className={styles.age} />
          </div>
        </div>
      </div>
      <div>
        <Button
          icon
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            ActionsMenuActions.openActionsMenu({
              type: 'user',
              user: {
                id: follower.identities.id || '',
                nodeId: follower.identities.nodeId || '',
                address: followerAddress,
                username:
                  follower.identities?.profilesCollection?.edges?.[0]?.node
                    ?.username || undefined,
              },
              page: 0,
              title:
                follower.identities?.profilesCollection?.edges?.[0]?.node
                  ?.username || ' ',
              description:
                follower.identities?.profilesCollection?.edges?.[0]?.node
                  ?.bio || undefined,
              image: getImageUrl(
                follower.identities?.profilesCollection?.edges?.[0]?.node
                  ?.avatar_uri || ''
              ),
            });
          }}
          tooltip="Actions"
          tooltipSide="left"
        >
          <RemixIcon icon="more-fill" size={16} />
        </Button>
      </div>
    </div>
  );
}
