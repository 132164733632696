/**
 * @generated SignedSource<<9a46e37977fc977bc56ccb3494772d4d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type enum_event_type = "add_tezos_token_to_playlist" | "block" | "collect_for_free" | "create_tezos_token" | "follow" | "mint_for_free" | "mint_tezos_token" | "pending_mint_tezos_token" | "pending_purchase_tezos_token" | "purchase_tezos_token" | "repost" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type FeedCardTokenFeedCardWrapperEventFragment$data = {
  readonly type: enum_event_type;
  readonly " $fragmentSpreads": FragmentRefs<"FeedCardHeaderEventFragment">;
  readonly " $fragmentType": "FeedCardTokenFeedCardWrapperEventFragment";
};
export type FeedCardTokenFeedCardWrapperEventFragment$key = {
  readonly " $data"?: FeedCardTokenFeedCardWrapperEventFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeedCardTokenFeedCardWrapperEventFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeedCardTokenFeedCardWrapperEventFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FeedCardHeaderEventFragment"
    }
  ],
  "type": "events",
  "abstractKey": null
};

(node as any).hash = "a8dd234c4e57dbaaa0299bad43e9dbbf";

export default node;
