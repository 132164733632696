import * as ScrollArea from '@radix-ui/react-scroll-area';
import React from 'react';
import styles from '@/view/styles/components/ScrollableContainer.module.scss';
import clsx from 'clsx';
import CardHeader from './Card/CardHeader';
import Card from './Card/Card';

export default function ScrollableContainer({
  variant = 'default',
  title,
  children,
  className,
  onTitleClick,
  hideScrollbar,
  cols,
  rows,
  fullWidth,
  fullWidthPadding,
}: {
  variant?: 'default' | 'gradient' | 'transparent' | 'card';
  title?: string | React.ReactNode;
  onTitleClick?: string | (() => void);
  children?: React.ReactNode;
  className?: string;
  hideScrollbar?: boolean;
  cols?: number;
  rows?: number;
  fullWidth?: boolean;
  fullWidthPadding?: 'sm' | 'md' | 'lg';
}) {
  const scrollAreaRef = React.useRef<HTMLDivElement>(null);

  const [scrollStart, setScrollStart] = React.useState(true);
  const [scrollEnd, setScrollEnd] = React.useState(false);

  const scrollLeft = () => {
    scrollAreaRef.current?.scrollBy({
      left: -scrollAreaRef.current.clientWidth,
      behavior: 'smooth',
    });
  };

  const scrollRight = () => {
    scrollAreaRef.current?.scrollBy({
      left: scrollAreaRef.current.clientWidth,
      behavior: 'smooth',
    });
  };

  React.useEffect(() => {
    if (scrollAreaRef.current) {
      if (scrollAreaRef.current.scrollLeft === 0) {
        setScrollEnd(false);
      } else if (
        Math.round(scrollAreaRef.current.scrollLeft) >=
        Math.round(scrollWidthRef.current) - 5
      ) {
        setScrollEnd(true);
      }
    }
  }, []);

  const scrollWidthRef = React.useRef<number>(0);

  const updateScrollWidth = (e: React.UIEvent) => {
    scrollWidthRef.current =
      e.currentTarget.scrollWidth - e.currentTarget.clientWidth;
  };

  return (
    <Card className={clsx(styles[variant], className)} variant={variant}>
      {title && (
        <CardHeader
          title={title}
          onTitleClick={onTitleClick}
          action="scroll"
          onScrollLeft={scrollLeft}
          onScrollRight={scrollRight}
          scrollStart={scrollStart}
          scrollEnd={scrollEnd}
          fullWidth={fullWidth}
          fullWidthPadding={fullWidthPadding}
        />
      )}
      <ScrollArea.Root
        className={clsx(
          styles.ScrollableContainerScrollAreaRoot,
          fullWidth && styles.fullWidthRoot
        )}
        type="hover"
      >
        <ScrollArea.Viewport
          data-cols={cols || 4}
          className={clsx(
            styles.ScrollableContainerScrollAreaViewport,
            styles.ScrollableContainerScrollAreaViewportHorizontal,
            rows && styles.multipleRows,
            rows && styles[`rows-${rows}`],
            fullWidth && fullWidthPadding && styles.fullWidthPadding,
            fullWidth && fullWidthPadding && styles[fullWidthPadding]
          )}
          ref={scrollAreaRef}
          onScroll={e => {
            updateScrollWidth(e);
            if (e.currentTarget.scrollLeft === 0) {
              setScrollStart(true);
            } else {
              setScrollStart(false);
            }
            if (
              Math.round(e.currentTarget.scrollLeft) >=
              Math.round(scrollWidthRef.current) - 5
            ) {
              setScrollEnd(true);
            } else {
              setScrollEnd(false);
            }
          }}
        >
          {children}
        </ScrollArea.Viewport>
        <ScrollArea.Scrollbar
          orientation={'horizontal'}
          className={clsx(
            styles.ScrollableContainerScrollAreaScrollbar,
            hideScrollbar && styles.altViewScrollAreaScrollbar
          )}
        >
          <ScrollArea.Thumb />
        </ScrollArea.Scrollbar>
        <ScrollArea.Corner />
      </ScrollArea.Root>
    </Card>
  );
}
