import React from 'react';
import styles from '@/view/styles/components/Card/CardHeader.module.scss';
import Button from '../Button';
import {CardHeaderType} from '@/view/types/types';
import clsx from 'clsx';
import {trim} from '@/utils/trim';
import LinkTo from '../LinkTo';
import RemixIcon from '../RemixIcon';

export default function CardHeader(
  props:
    | CardHeaderType<'actions'>
    | CardHeaderType<'scroll'>
    | CardHeaderType<'more'>
    | CardHeaderType<'activity'>
    | CardHeaderType<'none'>
) {
  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
  };
  return (
    <div
      className={clsx(
        styles.header,
        props.action === 'activity' && styles.activityHeader,
        props.fullWidth &&
          props.fullWidthPadding &&
          styles[props.fullWidthPadding]
      )}
    >
      <div
        className={clsx(
          styles.title,
          props.onTitleClick && styles.pointer,
          props.action === 'activity' && styles.activityTitle
        )}
        onClick={
          typeof props.onTitleClick === 'string'
            ? () => {}
            : props.onTitleClick
            ? props.onTitleClick
            : () => {}
        }
      >
        {typeof props.onTitleClick === 'string' ? (
          <LinkTo href={props.onTitleClick}>
            <a>{props.title}</a>
          </LinkTo>
        ) : (
          props.title
        )}
        {props.action === 'activity' && (
          <div className={styles.transactionHash}>
            <a href="#" target="_blank" rel="noopener noreferrer">
              ({trim(props.transactionHash, 5)})
            </a>{' '}
            <Button
              icon
              onClick={() => {
                copyToClipboard(props.transactionHash);
              }}
            >
              <RemixIcon icon="file-copy-line" size={16} />
            </Button>
          </div>
        )}
      </div>
      {props.action === 'actions' && (
        <Button
          icon
          onClick={() => {
            props.onActionClick?.();
          }}
          tooltip="Open actions menu"
          tooltipSide="right"
        >
          <RemixIcon icon="more-line" size={22} />
        </Button>
      )}
      {props.action === 'scroll' && (
        <div className={styles.secondaryActions}>
          <Button
            icon
            onClick={() => {
              props.onScrollLeft();
            }}
            disabled={props.scrollStart}
          >
            <RemixIcon icon="arrow-left-s-line" size={22} />
          </Button>
          <Button
            icon
            onClick={() => {
              props.onScrollRight();
            }}
            disabled={props.scrollEnd}
          >
            <RemixIcon icon="arrow-right-s-line" size={22} />
          </Button>
        </div>
      )}
      {props.action === 'more' && (
        <Button
          className={clsx(
            styles.moreButton,
            props.onTitleClick && styles.pointer
          )}
          onClick={e => {
            if (typeof props.onMore === 'string') {
              return;
            }
            e.stopPropagation();
            props.onMore?.();
          }}
        >
          {typeof props.onTitleClick === 'string' ? (
            <LinkTo href={props.onTitleClick}>
              <a>{props.moreText}</a>
            </LinkTo>
          ) : (
            props.moreText
          )}
        </Button>
      )}
      {props.action === 'activity' && <div>{props.date}</div>}
    </div>
  );
}
