import React from 'react';
import styles from '@/view/styles/components/FeedCard/FeedCard.module.scss';
import FeedCardActions from './blocs/FeedCardActions';
import FeedCardDescription from './blocs/FeedCardDescription';
import FeedCardAsset from './blocs/FeedCardAsset';
import FeedCardHeader from './blocs/FeedCardHeader';
import {graphql, useFragment} from '@/kits/relay-kit/src';
import ManageItem from '../ManageItem/ManageItem';
import FollowFeedCard from './FollowFeedCard';
import QuoteCard from './QuoteCard';
import {FeedCardEventFragment$key} from './__generated__/FeedCardEventFragment.graphql';
import {FeedCardTokenFeedCardWrapperEventFragment$key} from './__generated__/FeedCardTokenFeedCardWrapperEventFragment.graphql';
import {FeedCardTokenFeedCardWrapperTokenFragment$key} from './__generated__/FeedCardTokenFeedCardWrapperTokenFragment.graphql';

const FeedCardEventFragment = graphql`
  fragment FeedCardEventFragment on events
  @argumentDefinitions(
    includeFollow: {type: "Boolean", defaultValue: false}
    includeToken: {type: "Boolean", defaultValue: false}
  ) {
    type
    ...FollowFeedCardEventFragment @include(if: $includeFollow)
    ...FeedCardTokenFeedCardWrapperEventFragment @include(if: $includeToken)
    tezos_tokens {
      id
      nodeId
      ...FeedCardTokenFeedCardWrapperTokenFragment @include(if: $includeToken)
    }
  }
`;

export default function FeedCard({
  eventKey,
}: {
  eventKey: FeedCardEventFragment$key;
}) {
  const event = useFragment(FeedCardEventFragment, eventKey);
  if (event.type === 'follow') {
    return (
      <div className={styles.cardWrapper}>
        <FollowFeedCard eventKey={event} />
      </div>
    );
  } else {
    return (
      event.tezos_tokens && (
        <TokenFeedCardWrapper
          eventKey={event}
          tokenFragment={event.tezos_tokens}
        />
      )
    );
  }
}

const FeedCardTokenFeedCardWrapperTokenFragment = graphql`
  fragment FeedCardTokenFeedCardWrapperTokenFragment on tezos_tokens {
    id
    nodeId
    type
    mime_type
    allow_comments
    commentsCollection {
      edges {
        node {
          id
          parent_comment_id
          accounts {
            id
            nodeId
            identities {
              profilesCollection {
                edges {
                  node {
                    username
                  }
                }
              }
            }
            ...CommentIsReplyToFragment
          }
          ...CommentFragment
        }
      }
    }
    ...FeedCardHeaderTokenFragment
    ...FeedCardAssetTokenFragment
    ...FeedCardDescriptionTokenFragment
    ...ManageItemTokenFragment
    ...FeedCardActionsTokenFragment
  }
`;

const FeedCardTokenFeedCardWrapperEventFragment = graphql`
  fragment FeedCardTokenFeedCardWrapperEventFragment on events {
    type
    ...FeedCardHeaderEventFragment
  }
`;

const TokenFeedCardWrapper = ({
  eventKey,
  tokenFragment,
}: {
  eventKey: FeedCardTokenFeedCardWrapperEventFragment$key;
  tokenFragment: FeedCardTokenFeedCardWrapperTokenFragment$key;
}) => {
  const event = useFragment(
    FeedCardTokenFeedCardWrapperEventFragment,
    eventKey
  );
  const token = useFragment(
    FeedCardTokenFeedCardWrapperTokenFragment,
    tokenFragment
  );

  const isPostType = token.type === 'post';
  const isTextPost = isPostType && token.mime_type === 'text/plain';

  const isLinkType = token.type === 'link';

  const [newlyAddedReplies, setNewlyAddedReplies] = React.useState<string[]>(
    []
  );

  const [quoteItem, setQuoteItem] = React.useState<string | undefined>(
    undefined
  );

  return (
    <div className={styles.cardWrapper}>
      <div>
        <div className={styles.feedcard}>
          <FeedCardHeader token={token} event={event} />
          {!isPostType && (
            <>
              <FeedCardAsset tokenKey={token} />
              <ManageItem tokenKey={token} className={styles.assetManageItem} />
            </>
          )}
          {isPostType && (
            <FeedCardDescription
              tokenKey={token}
              showDescription={isPostType || isLinkType}
              setQuoteItem={setQuoteItem}
            />
          )}
          {!isPostType && (
            <FeedCardDescription
              tokenKey={token}
              showDescription
              setQuoteItem={setQuoteItem}
              hidden
            />
          )}
          {isPostType && !isTextPost && (
            <>
              <FeedCardAsset tokenKey={token} />
              <ManageItem tokenKey={token} className={styles.assetManageItem} />
            </>
          )}
          {quoteItem && !Number.isNaN(Number(quoteItem)) && (
            <QuoteCard
              tokenId={quoteItem}
              parentHasAsset={token.type !== 'post' || !isTextPost}
            />
          )}
          <FeedCardActions
            allowComments={token?.allow_comments || false}
            comments={
              token.commentsCollection?.edges
                .map(edge => ({
                  key: edge.node?.id,
                  node: edge.node,
                  isAReplyTo: !!edge.node.parent_comment_id
                    ? token.commentsCollection?.edges.find(
                        e => e.node.id === edge.node.parent_comment_id
                      )?.node.accounts
                    : undefined,
                }))
                .filter(c => {
                  return (
                    c.isAReplyTo === undefined ||
                    newlyAddedReplies.includes(c.key)
                  );
                }) || []
            }
            tokenKey={token}
            setNewlyAddedReplies={setNewlyAddedReplies}
          />
        </div>
      </div>
    </div>
  );
};
