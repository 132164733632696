import React from 'react';
import Paper from '../Paper';
import styles from '@/view/styles/components/Card/Card.module.scss';
import clsx from 'clsx';

export default function Card({
  children,
  className,
  variant = 'default',
}: {
  children:
    | [React.ReactNode] /* Body */
    | [React.ReactNode, React.ReactNode] /* Header, Body */;
  className?: string;
  variant?: 'default' | 'gradient' | 'transparent' | 'card';
}) {
  return (
    <Paper className={clsx(styles.root, styles[variant], className)}>
      {children.length === 2 ? (
        <>
          {children[0]}
          {children[1]}
        </>
      ) : children.length === 1 ? (
        <>{children[0]}</>
      ) : null}
    </Paper>
  );
}
