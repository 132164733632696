/**
 * @generated SignedSource<<11e1502897ff9391e2bfb0deb38b4332>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FeedCardActionsTokenFragment$data = {
  readonly accounts: {
    readonly identities: {
      readonly id: string;
      readonly profilesCollection: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly username: string | null;
          };
        }>;
      } | null;
    };
  };
  readonly allow_comments: boolean;
  readonly commentsCollection: {
    readonly totalCount: number;
  } | null;
  readonly editions: string;
  readonly editions_minted: string | null;
  readonly id: string;
  readonly nodeId: string;
  readonly playlists_tokensCollection: {
    readonly totalCount: number;
  } | null;
  readonly tokens_reactionsCollection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly nodeId: string;
      };
    }>;
  } | null;
  readonly tokens_sharesCollection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly quote_post_count: string;
        readonly repost_count: string;
        readonly share_count: string;
      };
    }>;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"PostActionsStatsTokenFragment" | "PostActionsTokenFragment" | "PostCollectActionsFragment">;
  readonly " $fragmentType": "FeedCardActionsTokenFragment";
};
export type FeedCardActionsTokenFragment$key = {
  readonly " $data"?: FeedCardActionsTokenFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeedCardActionsTokenFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeId",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeedCardActionsTokenFragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "editions",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "editions_minted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "commentsConnection",
      "kind": "LinkedField",
      "name": "commentsCollection",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": {
            "share_count": "DescNullsLast"
          }
        }
      ],
      "concreteType": "tokens_sharesConnection",
      "kind": "LinkedField",
      "name": "tokens_sharesCollection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "tokens_sharesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "tokens_shares",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "repost_count",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "quote_post_count",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "share_count",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "tokens_sharesCollection(first:1,orderBy:{\"share_count\":\"DescNullsLast\"})"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "tokens_reactionsConnection",
      "kind": "LinkedField",
      "name": "tokens_reactionsCollection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "tokens_reactionsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "tokens_reactions",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "playlists_tokensConnection",
      "kind": "LinkedField",
      "name": "playlists_tokensCollection",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "accounts",
      "kind": "LinkedField",
      "name": "accounts",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "identities",
          "kind": "LinkedField",
          "name": "identities",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "profilesConnection",
              "kind": "LinkedField",
              "name": "profilesCollection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "profilesEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "profiles",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "username",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allow_comments",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PostActionsTokenFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PostActionsStatsTokenFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PostCollectActionsFragment"
    }
  ],
  "type": "tezos_tokens",
  "abstractKey": null
};
})();

(node as any).hash = "a451f5407b0705f9d2b741d2acb49a6c";

export default node;
