import React, {Suspense, useCallback} from 'react';
import styles from '@/view/styles/components/FeedCard/blocs/FeedCardActions.module.scss';
import confetti from 'canvas-confetti';
import {Comment} from '../../Comment';
import Button from '../../Button';
import PostActions, {PostStats} from '../../PostActions/PostActions';
import {useLoginModalActions} from '@/state/hooks/auth/loginModal';
import {COMMENT_LOGIN_MESSAGE} from '@/content';
import {useAccount} from '@/kits/account-kit/src';
import {useBreakpoint} from '@/controller/hooks/utils/breakpoint';
import {trim} from '@/utils/trim';
import NFTCommentBox from '../../NFTCommentBox';
import updateCommentMutation from '@/graphql/update-comment';
import type {updateCommentMutation as UpdateCommentMutationType} from '@/graphql/__generated__/updateCommentMutation.graphql';
import {graphql, useFragment, useMutation} from '@/kits/relay-kit/src';
import PostCollectActions from '../../PostActions/blocs/PostCollectActions';
import {ItemModalActions} from '@/state/hooks/itemModal';
import TokenPageLink from '../../TokenPageLink';
import {useRouter} from 'next/router';
import {getMediaLink} from '@/state/hooks/mediaPlaylistFunctions';
import {FeedCardActionsTokenFragment$key} from './__generated__/FeedCardActionsTokenFragment.graphql';
import {CommentFragment$key} from '../../__generated__/CommentFragment.graphql';
import {CommentIsReplyToFragment$key} from '../../__generated__/CommentIsReplyToFragment.graphql';

const FeedCardActionsTokenFragment = graphql`
  fragment FeedCardActionsTokenFragment on tezos_tokens {
    id
    nodeId
    editions
    editions_minted
    commentsCollection {
      totalCount
    }
    tokens_sharesCollection(first: 1, orderBy: {share_count: DescNullsLast}) {
      edges {
        node {
          repost_count
          quote_post_count
          share_count
        }
      }
    }
    tokens_reactionsCollection {
      edges {
        node {
          id
          nodeId
        }
      }
    }
    playlists_tokensCollection {
      totalCount
    }
    accounts {
      identities {
        id
        profilesCollection {
          edges {
            node {
              username
            }
          }
        }
      }
    }
    allow_comments
    ...PostActionsTokenFragment
    ...PostActionsStatsTokenFragment
    ...PostCollectActionsFragment
  }
`;

export default function FeedCardActions({
  tokenKey,
  comments,
  setNewlyAddedReplies,
  allowComments,
}: {
  tokenKey: FeedCardActionsTokenFragment$key;
  comments: {
    node: CommentFragment$key;
    isAReplyTo: CommentIsReplyToFragment$key | null | undefined;
    key: string;
  }[];
  setNewlyAddedReplies: React.Dispatch<React.SetStateAction<string[]>>;
  allowComments: boolean;
}) {
  const token = useFragment(FeedCardActionsTokenFragment, tokenKey);

  const router = useRouter();

  const {getAccount} = useAccount();

  const user = getAccount();
  const identityId = user?.isLoggedIn ? user.identityId : undefined;
  const loggedIn = user.isLoggedIn || false;
  const [visibleComments, setVisibleComments] = React.useState(0);

  const inputRef = React.useRef<HTMLInputElement>(null);
  const focusInput = () => {
    const input = inputRef.current;
    if (!input) return;
    input.focus();
  };

  const [showConfetti, setShowConfetti] = React.useState(false);

  const yay = useCallback(() => {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: {
        y: 0.9,
      },
    });
  }, []);

  React.useEffect(() => {
    if (showConfetti) {
      const timeout = setTimeout(() => {
        confetti.reset();
        setShowConfetti(false);
      }, 4000);
      yay();
      return () => clearTimeout(timeout);
    }
  }, [yay, showConfetti]);

  const [showCommentBox, setShowCommentBox] = React.useState(false);

  const [replyToCommentId, setReplyToCommentId] = React.useState<string | null>(
    null
  );
  const [replyToUsername, setReplyToUsername] = React.useState<string | null>(
    null
  );
  const [replyingTo, setReplyingTo] = React.useState<string | null>(null);
  const [editingCommentId, setEditingCommentId] = React.useState<string | null>(
    null
  );
  const [editingCommentNodeId, setEditingCommentNodeId] = React.useState<
    string | null
  >(null);

  React.useEffect(() => {
    if (replyToCommentId) {
      setReplyingTo('@' + trim(replyToUsername || ''));
    } else {
      setReplyingTo(null);
    }
  }, [replyToCommentId, replyToUsername]);

  const isMyToken = token.accounts.identities.id === identityId;

  const {isMobile} = useBreakpoint();

  const editionsAvailable = `${
    parseInt(token.editions || '0') - parseInt(token.editions_minted || '0')
  }/${parseInt(token.editions || '0')} available`;

  const [editComment, {loading: editingComment}] =
    useMutation<UpdateCommentMutationType>(updateCommentMutation);

  const updateComment = () => {
    if (!editingCommentId) return;
    editComment({
      variables: {
        filter: {
          id: {
            eq: editingCommentId,
          },
        },
        input: {
          content: inputRef?.current?.value,
        },
      },
      updater: store => {
        const updatedData = store.getRootField('updatecommentsCollection');
        const newContent = updatedData
          ?.getLinkedRecords('records')?.[0]
          ?.getValue('content');
        if (!editingCommentNodeId) return;
        const newComment = store.get(editingCommentNodeId);
        if (newComment) {
          newComment.setValue(newContent, 'content');
        }
      },
      onCompleted() {
        setEditingCommentId(null);
        setEditingCommentNodeId(null);
        setReplyToCommentId(null);
        setReplyToUsername(null);
      },
    });
  };

  return (
    <>
      <TokenPageLink
        tokenId={token?.id || ''}
        username={
          token?.accounts.identities.profilesCollection?.edges?.[0]?.node
            .username || undefined
        }
        className={styles.stats}
      >
        <PostStats
          commentCount={token.commentsCollection?.totalCount || 0}
          repostCount={
            parseInt(
              token.tokens_sharesCollection?.edges?.[0]?.node?.repost_count ||
                '0'
            ) +
            parseInt(
              token.tokens_sharesCollection?.edges?.[0]?.node
                ?.quote_post_count || '0'
            )
          }
          reactionsCount={token.tokens_reactionsCollection?.edges.length || 0}
          shareCount={
            Number(
              token.tokens_sharesCollection?.edges?.[0]?.node?.share_count ||
                '0'
            ) || 0
          }
          savedCount={token.playlists_tokensCollection?.totalCount || 0}
          tokenKey={token}
          setShowComments={() => {
            if (isMobile) {
              if (!loggedIn) {
                useLoginModalActions.setShowLoginModal(
                  true,
                  COMMENT_LOGIN_MESSAGE
                );
                return;
              }
              if (!token) return;
              router
                .push(
                  {pathname: router.pathname, query: {...router.query}},
                  getMediaLink(
                    token.id,
                    token.accounts?.identities?.profilesCollection?.edges?.[0]
                      ?.node?.username || undefined
                  ),
                  {shallow: true}
                )
                .then(() => {
                  ItemModalActions.setItem(
                    true,
                    token.id,
                    token.accounts?.identities?.profilesCollection?.edges?.[0]
                      ?.node?.username
                  );
                });
              return;
            }
            setShowCommentBox(true);
            setTimeout(() => {
              focusInput();
            }, 300);
          }}
          editionsText={isMobile ? undefined : editionsAvailable}
        />
      </TokenPageLink>
      {token && (
        <TokenPageLink
          tokenId={token?.id || ''}
          username={
            token?.accounts.identities.profilesCollection?.edges?.[0]?.node
              .username || undefined
          }
          className={styles.actions}
        >
          <PostActions tokenKey={token} collect={!isMobile} />
        </TokenPageLink>
      )}
      {isMobile && (
        <div className={styles.stats}>
          <PostStats tokenKey={token} editionsText={editionsAvailable} />
          <PostCollectActions tokenKey={token} />
        </div>
      )}
      {(comments.length || 0) > 0 && (
        <>
          <div
            className={styles.commentsList}
            // data-is-text-post={isTextPost}
            data-has-comments={visibleComments > 0 ? true : false}
          >
            {comments
              .slice(-1 * (visibleComments + (isMyToken ? 2 : 1) || 1))
              .map(comment => (
                <Suspense key={comment.key}>
                  <Comment
                    commentKey={comment.node}
                    hideAvatar={isMobile}
                    isAReplyToKey={comment.isAReplyTo}
                    setReplyToUsername={setReplyToUsername}
                    setReplyToCommentId={id => {
                      setShowCommentBox(true);
                      setReplyToCommentId(id);
                      setTimeout(() => {
                        focusInput();
                      }, 300);
                    }}
                    onEdit={(
                      commentContent: string,
                      commentId: string,
                      commentNodeId: string,
                      parentId?: string
                    ) => {
                      setShowCommentBox(true);
                      setEditingCommentId(commentId);
                      setEditingCommentNodeId(commentNodeId);
                      if (parentId) {
                        setReplyToCommentId(parentId);
                      }
                      setTimeout(() => {
                        if (inputRef.current) {
                          inputRef.current.value = commentContent;
                        }
                        focusInput();
                      }, 100);
                    }}
                    editingComment={editingComment}
                  />
                </Suspense>
              ))}
          </div>
          {(token.commentsCollection?.totalCount || 0) > 0 ? (
            <Button
              text
              className={styles.viewAllComments}
              onClick={() => {
                router
                  .push(
                    {pathname: router.pathname, query: {...router.query}},
                    getMediaLink(
                      token.id,
                      token.accounts?.identities?.profilesCollection?.edges?.[0]
                        ?.node?.username || undefined
                    ),
                    {shallow: true}
                  )
                  .then(() => {
                    ItemModalActions.setItem(
                      true,
                      token.id,
                      token.accounts?.identities?.profilesCollection?.edges?.[0]
                        ?.node?.username
                    );
                  });
              }}
            >
              <span>
                View {token.commentsCollection?.totalCount || 0} comments
              </span>
            </Button>
          ) : null}
        </>
      )}
      {showCommentBox && (
        <div
          className={styles.commentBox}
          data-disabled={!identityId}
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          {!allowComments ? (
            <div className={styles.commentsAreDisabled}>
              Comments are disabled on this post
            </div>
          ) : (
            <>
              <div>
                <div className={styles.inputWrapper}>
                  {user.isLoggedIn ? (
                    <NFTCommentBox
                      replyToCommentId={replyToCommentId}
                      replyingTo={replyingTo}
                      resetReplyTo={() => {
                        setReplyToCommentId(null);
                        setReplyToUsername(null);
                        setEditingCommentId(null);
                        setEditingCommentNodeId(null);
                      }}
                      onAdd={(commentId, replyId) => {
                        setReplyToCommentId(null);
                        setReplyToUsername(null);
                        replyId &&
                          setNewlyAddedReplies(prev => [...prev, replyId]);
                        setVisibleComments(prevState => prevState + 1);
                      }}
                      allowComments={token.allow_comments || false}
                      tokenId={token?.id || ''}
                      tokenNodeId={token?.nodeId || ''}
                      ref={inputRef}
                      editing={!!editingCommentId}
                      onUpdate={updateComment}
                    />
                  ) : (
                    <div className={styles.signInToComment}>
                      <Button
                        accent
                        text
                        onClick={() => {
                          useLoginModalActions.setShowLoginModal(
                            true,
                            COMMENT_LOGIN_MESSAGE
                          );
                        }}
                      >
                        <span>Sign in</span>
                      </Button>{' '}
                      to comment
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
}
