import React from 'react';
import styles from '@/view/styles/components/FeedCard/FollowFeedCard.module.scss';
import {graphql, useFragment} from '@/kits/relay-kit/src';
import FollowFeedCardHeader from './blocs/FollowFeedCardHeader';
import {FollowFeedCardEventFragment$key} from './__generated__/FollowFeedCardEventFragment.graphql';

const FollowFeedCardEventFragment = graphql`
  fragment FollowFeedCardEventFragment on events {
    ...FollowFeedCardHeaderEventFragment
    follows {
      followee {
        accountsCollection {
          edges {
            node {
              id
            }
          }
        }
      }
    }
    accounts {
      id
      nodeId
      ...FollowFeedCardHeaderFollowerFragment
    }
  }
`;

export default function FollowFeedCard({
  eventKey,
}: {
  eventKey: FollowFeedCardEventFragment$key;
}) {
  const event = useFragment(FollowFeedCardEventFragment, eventKey);

  return (
    <div className={styles.root}>
      {event.follows?.followee.accountsCollection?.edges?.[0] && (
        <FollowFeedCardHeader eventKey={event} follower={event.accounts} />
      )}
    </div>
  );
}
