/**
 * @generated SignedSource<<6ab21872023d464eebee1207e469ecb3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type enum_event_type = "add_tezos_token_to_playlist" | "block" | "collect_for_free" | "create_tezos_token" | "follow" | "mint_for_free" | "mint_tezos_token" | "pending_mint_tezos_token" | "pending_purchase_tezos_token" | "purchase_tezos_token" | "repost" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type FeedCardEventFragment$data = {
  readonly tezos_tokens: {
    readonly id: string;
    readonly nodeId: string;
    readonly " $fragmentSpreads": FragmentRefs<"FeedCardTokenFeedCardWrapperTokenFragment">;
  } | null;
  readonly type: enum_event_type;
  readonly " $fragmentSpreads": FragmentRefs<"FeedCardTokenFeedCardWrapperEventFragment" | "FollowFeedCardEventFragment">;
  readonly " $fragmentType": "FeedCardEventFragment";
};
export type FeedCardEventFragment$key = {
  readonly " $data"?: FeedCardEventFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeedCardEventFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "includeFollow"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "includeToken"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeedCardEventFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "condition": "includeFollow",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "FollowFeedCardEventFragment"
        }
      ]
    },
    {
      "condition": "includeToken",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "FeedCardTokenFeedCardWrapperEventFragment"
        }
      ]
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "tezos_tokens",
      "kind": "LinkedField",
      "name": "tezos_tokens",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "nodeId",
          "storageKey": null
        },
        {
          "condition": "includeToken",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "FeedCardTokenFeedCardWrapperTokenFragment"
            }
          ]
        }
      ],
      "storageKey": null
    }
  ],
  "type": "events",
  "abstractKey": null
};

(node as any).hash = "573f16f0195a4e773dd777d62b14d6b8";

export default node;
