/**
 * @generated SignedSource<<74a361aab4c8356ab476fe87a4d5052c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type enum_token_type = "audio" | "image" | "link" | "post" | "video" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type FeedCardTokenFeedCardWrapperTokenFragment$data = {
  readonly allow_comments: boolean;
  readonly commentsCollection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly accounts: {
          readonly id: string;
          readonly identities: {
            readonly profilesCollection: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly username: string | null;
                };
              }>;
            } | null;
          };
          readonly nodeId: string;
          readonly " $fragmentSpreads": FragmentRefs<"CommentIsReplyToFragment">;
        };
        readonly id: string;
        readonly parent_comment_id: string | null;
        readonly " $fragmentSpreads": FragmentRefs<"CommentFragment">;
      };
    }>;
  } | null;
  readonly id: string;
  readonly mime_type: string;
  readonly nodeId: string;
  readonly type: enum_token_type;
  readonly " $fragmentSpreads": FragmentRefs<"FeedCardActionsTokenFragment" | "FeedCardAssetTokenFragment" | "FeedCardDescriptionTokenFragment" | "FeedCardHeaderTokenFragment" | "ManageItemTokenFragment">;
  readonly " $fragmentType": "FeedCardTokenFeedCardWrapperTokenFragment";
};
export type FeedCardTokenFeedCardWrapperTokenFragment$key = {
  readonly " $data"?: FeedCardTokenFeedCardWrapperTokenFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeedCardTokenFeedCardWrapperTokenFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeedCardTokenFeedCardWrapperTokenFragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mime_type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allow_comments",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "commentsConnection",
      "kind": "LinkedField",
      "name": "commentsCollection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "commentsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "comments",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "parent_comment_id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "accounts",
                  "kind": "LinkedField",
                  "name": "accounts",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "identities",
                      "kind": "LinkedField",
                      "name": "identities",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "profilesConnection",
                          "kind": "LinkedField",
                          "name": "profilesCollection",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "profilesEdge",
                              "kind": "LinkedField",
                              "name": "edges",
                              "plural": true,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "profiles",
                                  "kind": "LinkedField",
                                  "name": "node",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "username",
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "CommentIsReplyToFragment"
                    }
                  ],
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "CommentFragment"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FeedCardHeaderTokenFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FeedCardAssetTokenFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FeedCardDescriptionTokenFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ManageItemTokenFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FeedCardActionsTokenFragment"
    }
  ],
  "type": "tezos_tokens",
  "abstractKey": null
};
})();

(node as any).hash = "2ed36ca5731acbf06df92e9c988d3849";

export default node;
