/**
 * @generated SignedSource<<acc8e3ba994f1deb92c8717d870dc93b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type enum_event_type = "add_tezos_token_to_playlist" | "block" | "collect_for_free" | "create_tezos_token" | "follow" | "mint_for_free" | "mint_tezos_token" | "pending_mint_tezos_token" | "pending_purchase_tezos_token" | "purchase_tezos_token" | "repost" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type FollowFeedCardHeaderEventFragment$data = {
  readonly accounts: {
    readonly identities: {
      readonly profilesCollection: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly username: string | null;
          };
        }>;
      } | null;
    };
    readonly " $fragmentSpreads": FragmentRefs<"UserItemFragment">;
  };
  readonly created_at: string;
  readonly follows: {
    readonly followee: {
      readonly accountsCollection: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly nodeId: string;
            readonly " $fragmentSpreads": FragmentRefs<"UserItemFragment">;
          };
        }>;
      } | null;
    };
  } | null;
  readonly id: string;
  readonly nodeId: string;
  readonly type: enum_event_type;
  readonly " $fragmentType": "FollowFeedCardHeaderEventFragment";
};
export type FollowFeedCardHeaderEventFragment$key = {
  readonly " $data"?: FollowFeedCardHeaderEventFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"FollowFeedCardHeaderEventFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeId",
  "storageKey": null
},
v2 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "UserItemFragment"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FollowFeedCardHeaderEventFragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "created_at",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "follows",
      "kind": "LinkedField",
      "name": "follows",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "identities",
          "kind": "LinkedField",
          "name": "followee",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "accountsConnection",
              "kind": "LinkedField",
              "name": "accountsCollection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "accountsEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "accounts",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v1/*: any*/),
                        (v2/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "accounts",
      "kind": "LinkedField",
      "name": "accounts",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "identities",
          "kind": "LinkedField",
          "name": "identities",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "profilesConnection",
              "kind": "LinkedField",
              "name": "profilesCollection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "profilesEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "profiles",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "username",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v2/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "events",
  "abstractKey": null
};
})();

(node as any).hash = "d94f5f5aa5b83f10060352423277c3b2";

export default node;
