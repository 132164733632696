import React from 'react';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import styles from '@/view/styles/components/Accordion/AccordionItem.module.scss';
import RemixIcon from '../RemixIcon';
import clsx from 'clsx';

export default function AccordionItem({
  value,
  header,
  content,
  disabled,

  fullWidthTrigger = false,
  triggerIcon,
  open,
}: {
  value: string;
  header: React.ReactNode;
  content: React.ReactNode;
  disabled?: boolean;

  fullWidthTrigger?: boolean;
  triggerIcon?: string | React.ReactNode;
  open: string | string[];
}) {
  return (
    <AccordionPrimitive.Item value={value} className={styles.root}>
      <AccordionPrimitive.Header
        className={styles.header}
        data-full-width={fullWidthTrigger}
      >
        {fullWidthTrigger ? (
          <AccordionPrimitive.Trigger
            className={styles.trigger}
            data-full-width={fullWidthTrigger}
            disabled={disabled}
          >
            {triggerIcon === undefined || typeof triggerIcon === 'string' ? (
              !disabled ? (
                <RemixIcon
                  icon={triggerIcon || 'arrow-down-s-line'}
                  size={20}
                  className={clsx(
                    triggerIcon === undefined && styles.triggerIconArrow,
                    triggerIcon === undefined &&
                      value === open &&
                      styles.triggerIconArrowOpen
                  )}
                />
              ) : (
                <span />
              )
            ) : (
              triggerIcon
            )}
            <span>{header}</span>
          </AccordionPrimitive.Trigger>
        ) : (
          <div className={styles.trigger} data-full-width={fullWidthTrigger}>
            <AccordionPrimitive.Trigger disabled={disabled}>
              {triggerIcon === undefined || typeof triggerIcon === 'string' ? (
                !disabled ? (
                  <RemixIcon
                    icon={triggerIcon || 'arrow-down-s-line'}
                    size={20}
                  />
                ) : (
                  <span />
                )
              ) : (
                triggerIcon
              )}
            </AccordionPrimitive.Trigger>
            <span>{header}</span>
          </div>
        )}
      </AccordionPrimitive.Header>
      <AccordionPrimitive.Content className={styles.content}>
        {content}
      </AccordionPrimitive.Content>
    </AccordionPrimitive.Item>
  );
}
